import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

import { ReactComponent as GeneralIconUrl } from "images/star-icon.svg";


const Title = tw.h4`text-3xl font-bold text-gray-900 text-center`;

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 lg:mx-4 sm:w-1/2 lg:w-1/4 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-0`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500 text-center`}
  }
  .name {
    ${tw`mb-2 mt-2 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "סטייליסטיות",
  subheading = "",
  description = "צוות סטייליסטיות בעלות ניסיון ומוניטין בתחום",
  cards = [
    {
      imageSrc:   "/assets/team/team-5.jpg",
      position: "אשה צריכה להיות שני דברים- קלאסית ואגדית",
      name: "אבישג נהרי",
      links: [
        {
          url: "https://bonjourwithme.com/",
          icon: 'לדף הבית',
        },
      ],
    },
    {
      imageSrc:   "/assets/team/team-4.jpg",
      position: "סטיילינג אישי לנשים ולגברים",
      name: "טל פז",
      links: [
        {
          url: "https://www.facebook.com/talpazstyle/",
          icon: 'לדף הבית',
        },
      ],
    },
    {
      imageSrc:   "/assets/team/team-3.jpg",
       position: "סטייליסטית אישית, מנחה סדנאות סטיילינג",
      name: "לורה פלדמן",
      links: [
        {
          url: "https://laurafeldman.ravpage.co.il/personalstyling",
          icon: 'לדף הבית',
        },
      ],
    },
    {
      imageSrc:   "/assets/team/team-2.jpg",
       position: "סטייליסטית אישית - תדמית ונוכחות",
      name: "יוליה קושנר",
      links: [
        {
          url: "https://yuliakstyle.co.il/",
          icon: 'לדף הבית',
        },
      ],
    },
    
    {
      imageSrc:   "/assets/team/team-1.jpg",
       position: "מרצה בנושא דימוי גוף חיובי",
      name: "אירה שייר",
      links: [
        {
          url: "http://card.stef-art.co.il/ira_styling.html",
          icon: 'לדף הבית',
        },
      ],
    },
  ]
}) => {
  return (
    <Container id="STLIS">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Title >{heading}</Title> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="name">{card.name}</span>
                <span className="position">{card.position}</span>
                <CardLinks style={{margin:"5px",color:"black"}}>
                  {card.links.map((link, linkIndex) => (
                    <a target="_blank" key={linkIndex} className="link" href={link.url}style={{color:"black",fontSize:"small",fontWeight:"bold",textDecoration:'underline'}} >
                    {link.icon}
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
