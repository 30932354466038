import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs} from "firebase/firestore";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";

import MoreInfoForm from "components/forms/MoreInfoForm.js";

import ClubJoin from "components/forms/ClubJoin.js";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const _firebaseConfig = {
  apiKey: "AIzaSyCvU-KtTKfC-PeX0rLHyP4SsQLycUjqFCo",
  authDomain: "bakbok-co-il.firebaseapp.com",
  databaseURL: "https://bakbok-co-il-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bakbok-co-il",
  storageBucket: "bakbok-co-il.appspot.com",
  messagingSenderId: "91574833898",
  appId: "1:91574833898:web:3dde96e0ff48faacc6fd93",
  measurementId: "G-MXE3YMSYDJ"
};

const firebaseConfig = {
  apiKey: "AIzaSyDynfej31U0MOSpqHz4zR1N6JNXIZ8IHWQ",
  authDomain: "stylizz-co.firebaseapp.com",
  projectId: "stylizz-co",
  storageBucket: "stylizz-co.appspot.com",
  messagingSenderId: "523006132430",
  appId: "1:523006132430:web:258e011af28f24b680909f",
  measurementId: "G-ZP689WG2QN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;



const db = getFirestore(app);
const dbRef = collection(db, "Customers");

var querySnapshot = null;



export default ({
  subheading = "חבילות בסטייל",
  heading = "חבילות ידע ",
  description = "אפשרות לרכוש ידע פרקטי שישמש אותך בכל קניית בגד חדש. הבנה בהתאמת בגדים כך שתחמיא לך ותשדר את המסר הנכון שאת רוצה לשדר לעולם",
  plans = null,
  primaryButtonText = "לפרטים נוספים",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "מועדון חברים",
      durationPrices: ["0", "$0"],
      mainFeature: "For Personal Blogs",
      features: [ "ניוזלטר שבועי","כל מה שחם באופנה","הטבות בחנויות מקומיות"]
    },
    {
      name: "בסיסי",
      durationPrices: ["39", "$0"],
      mainFeature: "For Personal Blogs",
      features: ["נקודות סטיילינג על סמך תמונה ומכתב אישי","אי-מייל אישי מסטייליסטית",  "טיפים מקצועיים", "קופוני סטייליסטית"]
    },
    {
      name: "מתקדם",
      durationPrices: ["129", "$499"],
      mainFeature: "Suited for Production Websites",
      features: ["פגישת זום","היכרות אישית", " 30 דק פגישה", "טיפים מקצועיים", "קופוני סטייליסטית", "זיכוי בגין שירותי סטיילינג", "התאמת סגנון לבוש"],
      featured: true
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

function AddInter()
{
        

         
  var data = {
    AboutMe: 'info',
    AskMeAbout:'sdfasd',
    imgProfile:'my image PATH'
   
  };



addDoc(dbRef, data)
.then(docRef => {
    console.log("Document has been added successfully");
})
.catch(error => {
    console.log(error);
})
}




const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const [modalIsOpen, setModalIsOpen] = useState(false);
  
const toggleModal = () => setModalIsOpen(!modalIsOpen);




const [modal_Club_IsOpen, setModal_Club_IsOpen] = useState(false);
  
const toggleClubModal = () => setModal_Club_IsOpen(!modal_Club_IsOpen);


  return (
    <Container>





        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
                <div className="text-center">
                   <MoreInfoForm></MoreInfoForm>
                </div>
          </div>
        </StyledModal>
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modal_Club_IsOpen}
          onRequestClose={toggleClubModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleClubModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
                <div className="text-center">
                   <ClubJoin></ClubJoin>
                </div>
          </div>
        </StyledModal>



      <ContentWithPaddingXl>
        <HeaderContainer>
         
          <Title>{heading}</Title>
         
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration" dir="rtl">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                  <span className="slash">  </span>
                  <span className="duration">ש"ח</span>
                </span>
                <span className="name">{plan.name}</span>
              
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature" dir="rtl">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton onClick={(index==0?toggleClubModal :  toggleModal)    }>{ (index==0?'להצטרפות':  primaryButtonText)}</BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
