import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const Title = tw.h4`text-3xl font-bold text-gray-900 text-center`;

export default ({
  subheading = "שאלות נפוצות",
  heading = "יש לך שאלה עבורנו ?",
  description = "ריכזנו מגוון שאלות ותשובות נפוצות למקום אחד",
  faqs = [
    {
      question: "האם אתם נותנים זיכוי לכל בגד שמצלמים לכם ?",
      answer:
        "לא, אנו מציעים זיכוי רק עבור פריטים בהם אנו מזהים פוטנציאל למכור אותם ברווח , כמובן שעליהם להיות במצב טוב"
    },
    {
      question: "היכן ניתן לממש את הזיכויים שלכם ?",
      answer:
        "כל הצעה לזיכוי היא ספציפית לפריט שנשלח וכן תלוי בזיכוי שתקבלי מאיתנו , כעקרון הזיכויים מכובדים במגוון הרשתות וחנויות האופנה בארץ - בהצעה שתקבלי מאיתנו תופיע גם רשימת החנויות שמכבדות את הזיכוי עבור הבגד שתמסרי במקום"
    },
    {
      question: "האם יש סכום קבוע לזיכוי שמתקבל מכם עבור הפריטים ?",
      answer:
        "לא. גובה הזיכוי נקבע בהתאם לכל פריט ולהערכה שלנו לשיווי שלו , זיכוי יכול לנוע בין עשרות שקלים בודדים ואף למאות במידה והפריט מצדיק את המחיר בעינינו"
    },
    {
      question: "לפי מה נקבע שיווי הזיכוי ?",
      answer:
        "אנו בוחנים את הפריטים ביחד עם חנויות לפריטי יד שניה ומחפשים איכות , ייחודיות וכמובן מעריכים את מצב הבגד , על סמך ניסיון שלנו שולחים את ההצעה אליך עבור זיכוי כספי תמורת הבגד שצילמת"
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="QA">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
           {heading && <Title dir="rtl">{heading}</Title>}
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer dir="rtl">
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
