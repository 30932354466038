import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import FAQ from "components/faqs/SingleCol.js";

import HowItWorks   from "pages/HowItWorks.js"

import  StylingSendForm from "components/forms/StylingSendForm.js";

import ProfileThreeColGridCards from "components/cards/ProfileThreeColGrid.js"

import AboutUs from "components/features/AboutUs.js"


import tw from "twin.macro";

import Pricing from "components/pricing/PricingStylizz.js";



const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


export default () => (
  <div>
     <StyledDiv className="App">
    <Hero />

    <Features />

    {/*    
    <AboutUs/>
   <Pricing/>
    <ProfileThreeColGridCards></ProfileThreeColGridCards>
     */}
   
    <HowItWorks></HowItWorks>
    <FAQ/>
    {/*   
    <Blog />
    <Testimonial />
*/}
 
 <ContactUsForm />

{/*   
   <StylingSendForm></StylingSendForm>
 
*/}


    <Footer />
  </StyledDiv>
  </div>
);
