import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";


import StylingSendForm  from "../forms/StylingSendForm"


import HowItWorks   from "pages/HowItWorks.js"


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;



const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


export default () => {
  const cards = [
    {
      imageSrc:
        "/assets/iStock-618976674.jpg",
      subtitle: "פשוט ולעניין",
      title: "הכל סביבך",
      description:
        "היחשפי לשיטות וטכניקות של התאמת בגדים וחיבורים נכונים , מסרים ובטחון שבא ליידי ביטוי בדרך שבה את מתלבשת",
      url: "https://timerse.com"
    },

    {
      imageSrc:
      "/assets/iStock-1160533209.jpg",
      subtitle: "כל מה ששווה",
      title: "מראה נכון יכול להעביר מסרים ולקדם את המטרות שלך",
      description:
        "מחקרים מציגים תמונת מצב מענינת לגבי הקשר שבין הבגדים שאנו לובשים למצב הרב שלנו, בגדים מאפשרים לנו לשדר מסרים ולהרגיש בנח עם עצמנו. באמצעות ידע בתחום תוכלי להבין טוב יותר את כללי המשחק , מה נהוג ומה פחות ואיך לעשות זאת בחוכמה",
      url: "https://timerse.com"
    }
  ];

  const Description = tw(SectionDescription)`text-right mx-auto`;

  const DescriptionMAin = tw(SectionDescription)`text-center mx-auto`;

  const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

 
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const toggleModal = () => setModalIsOpen(!modalIsOpen);
  

  return (
    <Container id="AboutUs">




      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingDescription  style={{color:'black',fontSize:'30px'}} dir="rtl">אודות</HeadingDescription>
          <HeadingDescription >
        
          </HeadingDescription>
         <DescriptionMAin dir="rtl">
          נעים להכיר
          אני לירן, הבעלים מאחורי אתר סטייליזז.
          <br>
          </br>
            את האתר הקמתי לאחר היכרות עם עולם חדש של אופנה וסטיילינג ומשיחות רבות עם סטייליסטיות שחלקן גם סייעו בהקניית הידע עבורי.
          <br></br> <br></br>
            האתר משקף דרך לא קצרה שעברתי וספגתי בעולמות האופנה ומה המשמעות באמת לדעת להתלבש נכון ולהעביר מסרים באמצעות בגדים
            <br></br> <br></br>
            אשמח לקבל פניות , הצעות והארות 
            <br>
          </br>
             מקווה שתמצאו את האתר מועיל
            <br>
          </br>
          <br>
          </br>
          ניתן לשלוח לי הודעות גם למייל
          <br>
          </br>
          info@stylizz.co.il
         </DescriptionMAin>
      
        </HeadingInfoContainer>


      </SingleColumn>
     
    </Container>
  );
};
