
import React, { useState } from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs} from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDynfej31U0MOSpqHz4zR1N6JNXIZ8IHWQ",
  authDomain: "stylizz-co.firebaseapp.com",
  projectId: "stylizz-co",
  storageBucket: "stylizz-co.appspot.com",
  messagingSenderId: "523006132430",
  appId: "1:523006132430:web:258e011af28f24b680909f",
  measurementId: "G-ZP689WG2QN"
};


const _firebaseConfig = {
  apiKey: "AIzaSyCvU-KtTKfC-PeX0rLHyP4SsQLycUjqFCo",
  authDomain: "bakbok-co-il.firebaseapp.com",
  databaseURL: "https://bakbok-co-il-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bakbok-co-il",
  storageBucket: "bakbok-co-il.appspot.com",
  messagingSenderId: "91574833898",
  appId: "1:91574833898:web:3dde96e0ff48faacc6fd93",
  measurementId: "G-MXE3YMSYDJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 right-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`



const db = getFirestore(app);
const dbRef = collection(db, "users");

var querySnapshot = null;
async function getusers() {
  console.log('========= getusers');


  querySnapshot = await getDocs(collection(db, "users"));

 

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
  });

 
}







async function AddUser(UserInfo) {    


 var data = UserInfo;
  addDoc(dbRef, data)
  .then(docRef => {
      console.log("Document has been added successfully");
  })
  .catch(error => {
      console.log(error);
  })

       
        
}



export default () => {

  
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [msg, setMsg] = useState('');
const [responseMsg, setResponseMsg] = useState('');

const [phone, setPhone] = useState('');


function logInfo()
{

  var todayDate = new Date().toISOString().slice(0, 10);
  
var data = {
  name:name,
  email:email,
  msg:msg,
  phone:phone,
  date_entered:todayDate
 
};



 AddUser(data);


console.log(name + email + msg);

setMsg ("");
setEmail("");
setName("");
setPhone("");

setResponseMsg("הודעה נשלחה בהצלחה");

//getusers();

}
  return (
    <Container id="STYLINT">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
           
            <form action="#">
              <TwoColumn>
                <Column>
                 
                <InputContainer >
                    <Label htmlFor="email-input">טלפון</Label>
                    <Input id="phone-input" type="text" name="phone" placeholder="052-1234567" dir="rtl"   value={phone}   onChange={(e) => setPhone(e.target.value)}/>
                  </InputContainer>



                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                  <h5 style={{textAlign:'right'}}>ניצור איתך קשר ונספר לך על התהליך שלנו</h5>
                    

                  </InputContainer>
                </Column>
              </TwoColumn>
           
              <SubmitButton type="button" value="Submit" onClick={logInfo}>שליחה</SubmitButton>
              <span style={{padding:'20px'}}>{responseMsg}</span>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
