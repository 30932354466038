import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import StylizzHowItWorks from "components/features/StylizzHowItWorks.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import celebrationIcon from "images/celebration-icon.svg";


import checkboxCircle from "images/checkbox-circle.svg";





const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
   <div id="HIW">
      <StylizzHowItWorks 
       
        heading="איך זה עובד ?"
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "1. שליחת צילום בגד",
            description: "שלחי אלינו תמונה של בגד שעבורו תרצי לקבל זיכוי"
          },
          {
            imageSrc: ShieldIconImage,
            title: "2. קבלת הצעה",
            description: "קבלי מאיתנו הצעה לזיכוי כספי עבור הבגד"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "3. מסירה וקבלת זיכוי",
            description: "נשלח לך זימון לחנות ממנה תקבלי את הזיכוי עבור הבגד"
          },
        ]}
        linkText=""
      />
     </div>
    
  );
};
