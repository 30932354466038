import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import ClubJoin from "components/forms/ClubJoin.js";

import  '../../styles/downArrow.css';

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://cdn.pixabay.com/photo/2017/09/01/21/53/sunglasses-2705642_960_720.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);


  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#AboutUs">
        אודות
      </NavLink>
      <NavLink href="#HIW">
        איך זה עובד
      </NavLink>
   
      <NavLink href="#CNTCT">
        צור קשר
      </NavLink>
    </NavLinks>,
   
    <NavLinks key={2}>
      {/*
      <PrimaryLink href="/#">
        הצטרפות כסטייליסטית
      </PrimaryLink>
  */}

    </NavLinks>
    
  ];

  return (
   
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>

          <Heading>
          <span style={{color:'white',fontFamily:'tahoma'}}>Styli</span>
              <span style={{color:'red',fontFamily:'tahoma'}}>ZZ</span>
              <br></br>
            {/*   <span style={{color:'white',fontFamily:'tahoma',fontSize:'large'}}>ללמוד מחדש איך לובשים בגדים</span> */}
             
             {/* <span style={{color:'white',fontFamily:'tahoma',fontSize:'large'}}>לרכוש ידע. לדעת איך להתלבש</span>   */}
          </Heading>
          <br></br>
              <span style={{color:'white',fontFamily:'tahoma',fontSize:'large',textAlign:"center"}}>קבלי זיכויים 
              <br></br>
              על בגדים שכבר לא לובשת</span>
              <PrimaryAction  onClick={toggleModal}>קדימה, בואי נתחיל</PrimaryAction> 
        {/*   <PrimaryAction onClick={(e)=>{window.location.href="#TELLME"}}>ספרו לי עוד</PrimaryAction>  */}
          <p style={{fontSize:'30px'}}><i class="arrow down"></i></p>
         
        </Content>
      </HeroContainer>


      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" onClick={toggleModal}/>
          </CloseModalButton>
          <div className="content">
                <div className="text-center">
                  <ClubJoin   ></ClubJoin>
                </div>
          </div>
        </StyledModal>


    </Container>

   
  );
};
